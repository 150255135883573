import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ConfigLoaderService } from 'src/app/core/app-load/config-loader.service';
import { Room } from 'src/app/core/models/project/room.model';
import { DaliSceneElement } from 'src/app/core/models/scenes/dali-scene-element.model';
import { DaliScene } from 'src/app/core/models/scenes/dali-scene.model';
import { DemoService } from 'src/app/core/services/demo.service';
import { UserSettingsService } from 'src/app/core/services/user-settings.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { RoomViewService } from 'src/app/modules/rooms/services/room-view.service';
import { ApiDaliScenesService } from 'src/app/modules/scenes/api-dali-scenes.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-dali-scene-live',
  templateUrl: './dali-scene-live.component.html',
  styleUrl: './dali-scene-live.component.scss'
})
export class DaliSceneLiveComponent implements OnInit, OnDestroy {

  activeRoomId: string;
  daliSceneId: string;
  room: Room;
  daliScene: DaliScene;

  loadingSubscription: Subscription;
  loadingElements = [];

  backButtonSubscription: Subscription;

  private readonly CONFIG_URL = 'assets/config/config.json';
  webConfig

  sliderOptions = {
    floor : 0,
    ceil : 100
  };
  Number = Number;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
    private loadingService: LoadingService,
    private daliProjectService: DaliProjectService,
    public platform: Platform,
    private apiDaliScenesService: ApiDaliScenesService,
    private toastService: ToastService,
    private demoService: DemoService,
    private http: HttpClient,
    private configLoaderService: ConfigLoaderService,
    private apiDaliProjectService: ApiDaliProjectService,
    private roomViewService: RoomViewService,
    private userSettingsService: UserSettingsService
  ) {
  }


  ngOnInit(): void {
    this.activeRoomId = this.route.snapshot.paramMap.get('id');
    this.daliSceneId = this.route.snapshot.paramMap.get('daliSceneId');
    this.room = this.projectService.getRoom(this.activeRoomId);
    this.loadingSub();
    this.daliScene = this.getDaliScene(this.daliSceneId);
    this.daliProjectService.daliProjectChanged.subscribe(() => {
      this.daliScene = this.getDaliScene(this.daliSceneId);
    });

    if (!this.demoService.isDemo()) {
      if (this.platform.is('desktop')) {
        this.http.get<any>(this.CONFIG_URL).subscribe({
          next: (value) => this.webConfig = value
        });
      } else {
        this.webConfig = this.configLoaderService.getConfig().configuration;
      }
    }

    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, () => {
      this.goBack()
    });
  }


  getDaliScene(daliSceneId: string): DaliScene {
    const daliScene = this.daliProjectService.getDaliScene(daliSceneId);
    return Object.assign({}, daliScene)
  }

  loadingSub() {
    this.loadingElements = this.loadingService.getLoadingElements();
    this.loadingSubscription = this.loadingService.loadingChanged.subscribe( response => {
      this.loadingElements = this.loadingService.getLoadingElements();
    });
  }

  loadingElement(id: string) {
    return this.loadingElements.includes(id);
  }

  goBack() {
    const roomViewTypeSetting = this.userSettingsService.getRoomViewTypeSetting()
    if (roomViewTypeSetting === 'rooms') {
      this.router.navigate([`/rooms/${this.room.id}`]);
    } else {
      this.router.navigate([`/rooms`]);
    }
  }

  activateDaliScene() {
    this.loadingService.addToLoading(this.daliSceneId + 'on');
    this.apiDaliScenesService.activateDaliScene(this.daliSceneId).subscribe({
      next: (resp) => {
        if (!this.webConfig.SHOW_SCENE_STATUS) {
          this.toastService.showSceneTurnedOn();
        }
        this.loadingService.removeFromLoading(this.daliSceneId + 'on');
        },error:()=> {
          this.loadingService.removeFromLoading(this.daliSceneId + 'on');
        }
  });
  }

  toggleDaliSceneOff() {
    this.loadingService.addToLoading(this.daliSceneId + 'off');
    this.apiDaliScenesService.deactivateDaliScene(this.daliSceneId).subscribe({
      next: (resp) => {
        if (!this.webConfig.SHOW_SCENE_STATUS) {
          this.toastService.showSceneTurnedOff();
        }
        this.loadingService.removeFromLoading(this.daliSceneId + 'off');
        },error:()=> {
          this.loadingService.removeFromLoading(this.daliSceneId + 'off');
        }
  }, );
  }

  getDaliLightGroup(daliElement: DaliSceneElement) {
   return this.daliProjectService.getDaliLightsGroup('DG-'+daliElement.elementId)
  }


  getDaliLight(daliElement: DaliSceneElement) {
    return this.daliProjectService.getDaliLight('DL-'+daliElement.elementId)
  }

  ///////////////// DALI LIGHT

  toggleDaliLight(daliElement: DaliSceneElement) {
    const daliLight = this.daliProjectService.getDaliLight('DL-'+daliElement.elementId);
    if (Number(daliLight.equipmentProperties[0].value) === 0 ) {
      this.apiDaliProjectService.activateDaliLight(daliLight.id, 100).subscribe();
    } else {
      this.apiDaliProjectService.activateDaliLight(daliLight.id, 0).subscribe();
    }
  }

  getDaliLightValue(daliElement: DaliSceneElement) {
    const daliLight = this.daliProjectService.getDaliLight('DL-'+daliElement.elementId);
    return Number(daliLight.equipmentProperties[0].value);
  }


  daliLightOnStatus(daliElement: DaliSceneElement) {
    const daliLight = this.daliProjectService.getDaliLight('DL-'+daliElement.elementId);
    if (Number(daliLight.equipmentProperties[0].value) > 0) {
      return 'orange'
    } else {
      return 'ocean-blue'
    }
  }

  daliLightOffStatus(daliElement: DaliSceneElement) {
    const daliLight = this.daliProjectService.getDaliLight('DL-'+daliElement.elementId);
    if (Number(daliLight.equipmentProperties[0].value) === 0) {
      return 'orange'
    } else {
      return 'ocean-blue'
    }
  }

  ///////////////// DALI LIGHT  GROUP

  daliLightGroupOnStatus(daliElement: DaliSceneElement) {
    const daliLightsGroup = this.daliProjectService.getDaliLightsGroup('DG-'+daliElement.elementId);
    if ((daliLightsGroup.value > 0 && daliLightsGroup.value <= 100) || daliLightsGroup.value == 200) {
      return 'orange'
    } else {
      return 'ocean-blue'
    }
  }

  daliLightGroupOffStatus(daliElement: DaliSceneElement) {
    const daliLightsGroup = this.daliProjectService.getDaliLightsGroup('DG-'+daliElement.elementId);
    if (daliLightsGroup.value == 0 || daliLightsGroup.value == 200) {
      return 'orange'
    } else {
      return 'ocean-blue'
    }
  }

  toggleDaliLightGroupOn(daliElement: DaliSceneElement) {
    const daliLightsGroup = this.daliProjectService.getDaliLightsGroup('DG-'+daliElement.elementId);
    this.apiDaliProjectService.activateDaliLightsGroup(daliLightsGroup.id, 100).subscribe();
  }

  toggleDaliLightGroupOff(daliElement: DaliSceneElement) {
    const daliLightsGroup = this.daliProjectService.getDaliLightsGroup('DG-'+daliElement.elementId);
    this.apiDaliProjectService.activateDaliLightsGroup(daliLightsGroup.id, 0).subscribe();
  }



  toggleDaliLightOn(daliElement: DaliSceneElement) {
    const daliLight = this.daliProjectService.getDaliLight('DL-'+daliElement.elementId);
    this.apiDaliProjectService.activateDaliLight(daliLight.id, 100).subscribe();

  }

  toggleDaliLightOff(daliElement: DaliSceneElement) {
    const daliLight = this.daliProjectService.getDaliLight('DL-'+daliElement.elementId);
    this.apiDaliProjectService.activateDaliLight(daliLight.id, 0).subscribe();

  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
    }
  }
}
