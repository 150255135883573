<div class="content-header flex-row no-wrap">
  <div class="i-back-arrow" (click)="goBack()"></div>
  <h1 class="ellipsis">{{ room?.name | uppercase }}</h1>
</div>

<div class="flex-row flex-center">
  <ion-button
    [color]="daliScene.active && daliScene.isTwoState ? 'orange' : 'ocean-blue'"
    (click)="activateDaliScene()"
    style="width: 125px"
    >{{ "activate" | translate }}</ion-button
  >
  <div class="main-icon flex-row flex-center margin-left20 margin-right20">
    <div
      *ngIf="
        !loadingElement(daliScene.id + 'on') &&
        !loadingElement(daliScene.id + 'off')
      "
      class="i-scenes-bl-large"
    ></div>
    <div
      *ngIf="
        loadingElement(daliScene.id + 'on') ||
        loadingElement(daliScene.id + 'off')
      "
      class="lds-spinner-md margin-bottom4"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <ion-button
    *ngIf="daliScene.isTwoState"
    [color]="!daliScene.active ? 'orange' : 'ocean-blue'"
    style="width: 125px"
    (click)="toggleDaliSceneOff()"
    >{{ "deactivate" | translate }}</ion-button
  >
</div>
<div class="text-align-center margin-top30">{{ daliScene.name }}</div>
<div class="content-header flex-row no-wrap">
  <h2 class="ellipsis uppercase">{{ "Lights" | translate }}</h2>
</div>
<!--//////////////// NON DIMABLE DALI SCENE ELEMENTS ////////////////-->
@if (!daliScene.isDimmable) {
<ng-container *ngFor="let daliElement of daliScene.elements">
  <!--//////////////// NON DIMABLE DALI LIGHTS ////////////////-->
  @if (daliElement.elementType.toLowerCase() === 'light') {
  <div class="flex-row flex-center margin-top30">
    <ion-button
      [color]="daliLightOnStatus(daliElement)"
      (click)="toggleDaliLightOn(daliElement)"
      style="min-width: 80px"
      >{{ "On" | translate }}</ion-button
    >
    <div
      class="main-icon flex-row flex-center margin-left20 margin-right20"
      (click)="toggleDaliLight(daliElement)"
    >
      <div *ngIf="!loadingElement('DL-' + daliElement.elementId)">
        <div
          *ngIf="getDaliLightValue(daliElement) === 0"
          class="i-lightbulb-large-black"
        ></div>
        <div
          *ngIf="getDaliLightValue(daliElement) > 0"
          class="i-lightbulb-large-orange"
        ></div>
      </div>

      <div
        *ngIf="loadingElement('DL-' + daliElement.elementId)"
        class="lds-spinner-md margin-bottom4"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <ion-button
      [color]="daliLightOffStatus(daliElement)"
      (click)="toggleDaliLightOff(daliElement)"
      style="min-width: 80px"
      >{{ "Off" | translate }}</ion-button
    >
  </div>
  <div class="text-align-center margin-top20">
    {{ getDaliLight(daliElement).name }}
  </div>
  }
  <!--//////////////// NON DIMABLE DALI LIGHT GROUPS ////////////////-->
  @if (daliElement.elementType.toLowerCase() === 'group') {
  <div class="flex-row flex-center margin-top30">
    <ion-button
      [color]="daliLightGroupOnStatus(daliElement)"
      (click)="toggleDaliLightGroupOn(daliElement)"
      >{{ "On" | translate }}</ion-button
    >
    <div class="main-icon flex-row flex-center margin-left20 margin-right20">
      <div
        *ngIf="!loadingElement('DG-' + daliElement.elementId)"
        class="i-lightgroup-black-large"
      ></div>
      <div
        *ngIf="loadingElement('DG-' + daliElement.elementId)"
        class="lds-spinner-md margin-bottom4"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <ion-button
      [color]="daliLightGroupOffStatus(daliElement)"
      (click)="toggleDaliLightGroupOff(daliElement)"
      >{{ "Off" | translate }}</ion-button
    >
  </div>
  <div class="text-align-center margin-top20">
    {{ getDaliLightGroup(daliElement).name }}
  </div>

  }
</ng-container>
}

<!--//////////////// DIMABLE DALI SCENE ELEMENTS ////////////////-->
@if (daliScene.isDimmable) {
<ng-container *ngFor="let daliElement of daliScene.elements">
  <!--//////////////// DIMABLE DALI LIGHTS ////////////////-->
  @if (daliElement.elementType.toLowerCase() === 'light') {
  <app-dimmable-dali-light
    [daliLight]="getDaliLight(daliElement)"
    class="margin-top30"
    [mode]="'live'"
  ></app-dimmable-dali-light>
  <!--//////////////// DIMABLE DALI LIGHTS GROUPS ////////////////-->
  } @if (daliElement.elementType.toLowerCase() === 'group') {
  <app-dimmable-light-group
    [daliLightsGroup]="getDaliLightGroup(daliElement)"
    class="margin-top30"
    [mode]="'live'"
    [compactView]="true"
  >
  </app-dimmable-light-group>
  }
</ng-container>
}
